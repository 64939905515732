


































import { Component, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import DashTotalTipoCitasModule from "@/store/modules/Dashboards/Empresarial/DashTotalTipoCitas-module";
@Component({
  components: { VueApexCharts }
})
export default class ChartComparacionEntreMeses extends Vue {
  public created() {}
  public get series() {
    return DashTotalTipoCitasModule.DashTotalTipoCitas.citas_comparacion_meses;
  }
  public get estemes() {
    return DashTotalTipoCitasModule.DashTotalTipoCitas.sum_este_mes;
  }
  public get mespasado() {
    return DashTotalTipoCitasModule.DashTotalTipoCitas.sum_mes_pasado;
  }

  public get chartOptions() {
    return {
      chart: {
        toolbar: { show: false },
        dropShadow: {
          enabled: true,
          top: 5,
          left: 0,
          blur: 4,
          opacity: 0.1
        }
      },
      stroke: {
        curve: "smooth",
        dashArray: [0, 8],
        width: [4, 2]
      },
      grid: {
        borderColor: "#e7e7e7"
      },
      legend: {
        show: true
      },
      colors: ["#F97794", "#b8c2cc"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          inverseColors: false,
          gradientToColors: ["#7367F0", "#b8c2cc"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        }
      },
      markers: {
        size: 0,
        hover: {
          size: 5
        }
      },
      xaxis: {
        labels: {
          style: {
            cssClass: "text-grey fill-current"
          }
        },
        axisTicks: {
          show: false
        },
        categories: ["01", "05", "09", "13", "17", "21", "26", "31"],
        axisBorder: {
          show: false
        }
      },
      yaxis: {
        labels: {
          style: {
            cssClass: "text-grey fill-current"
          }
        }
      },
      tooltip: {
        x: { show: false }
      }
    };
  }
}
